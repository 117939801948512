<template>
  <q-page padding>
    <h5>Frase</h5>
    <q-form
      @submit.prevent="handleSubmit"
      class=" q-gutter-md"
    >
      <q-input
        label="Foto"
        v-model="img"
        type="file"
        stack-label
      />
      <q-input
        label="Frase"
        type="textarea"
        v-model="form.description"
        :rules="[ val => val && val.length > 0 || 'Favor informe uma frase valido!']"
        clearable
      />
      <q-select
        v-model="form.hashtags"
        :options="optionsHashtags"
        @filter="filterOptionsHashtags"
        @new-value="createValueHashtags"
        label="Hashtags"
        max-values="5"
        hint="Máximo 5 hashtags"
        use-input
        use-chips
        counter
        multiple
        clearable
        map-options
        emit-value
      />
      <q-select
        v-model="form.autor_id"
        :options="authors"
        label="Autor"
        option-value="id"
        option-label="name"
        map-options
        emit-value
        clearable
      />
      <q-select
        v-model="form.categoria_id"
        :options="categories"
        label="Categoria"
        option-value="id"
        option-label="name"
        map-options
        emit-value
        clearable
      />
      <q-btn
        :label=" isUpdated? 'Atualizar': 'Salvar'"
        color="positive"
        type="submit"
      />
      <q-btn
        label="Cancelar"
        color="warning"
        :to="{name:'phrase'}"
      />

    </q-form>
  </q-page>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import useApi from '../../composables/UseApi'
import useNotify from '../../composables/UseNotify'
import { useRouter, useRoute } from 'vue-router'
import { useQuasar } from 'quasar'
import { hashtags } from './table'

export default defineComponent({
  name: 'PageFormFrase',

  setup () {
    const router = useRouter()
    const route = useRoute()
    const $q = useQuasar()
    const table = 'Frase'
    const { post, getById, update, list, uploadImg } = useApi()
    const { notifyError, notifySuccess } = useNotify()
    const isUpdated = computed(() => route.params.id)

    onMounted(async () => {
      if (isUpdated.value) {
        await handleGetPhrase(isUpdated.value)
      }
      await handleGetAuthors()
      await handleGetCategories()
    })
    const authors = ref([])
    const categories = ref([])
    const optionsHashtags = ref(hashtags)
    const img = ref([])

    let phrase = {}

    const form = ref({
      description: '',
      autor_id: null,
      categoria_id: null,
      hashtags: [],
      img_url: null
    })

    const handleSubmit = async () => {
      $q.loading.show()
      try {
        if (img.value.length > 0) {
          const imgUrl = await uploadImg(img.value[0], 'phrases')
          form.value.img_url = imgUrl
        }
        if (isUpdated.value) {
          await update(table, form.value)
          notifySuccess('Frase atualizada com sucesso!')
        } else {
          await post(table, form.value)
          notifySuccess('Frase salva com sucesso!')
          $q.loading.hide()
        }
        router.push({ name: 'phrase' })
      } catch (error) {
        notifyError(error.message)
        $q.loading.hide()
      }
    }

    const handleGetPhrase = async (id) => {
      try {
        $q.loading.show()
        phrase = await getById(table, route.params.id)
        phrase.hashtags = phrase.hashtags.replace(/[[\]"]/g, '').split(',')
        form.value = phrase
        $q.loading.hide()
      } catch (error) {
        notifyError(error.message)
      }
    }

    const handleGetAuthors = async () => {
      try {
        $q.loading.show()
        authors.value = await list('Autor')
        $q.loading.hide()
        return authors
      } catch (error) {
        notifyError(error.message)
      }
    }

    const handleGetCategories = async () => {
      try {
        $q.loading.show()
        categories.value = await list('Categoria')
        $q.loading.hide()
        return categories
      } catch (error) {
        notifyError(error.message)
      }
    }

    const filterOptionsHashtags = (val, update) => {
      if (val === '') {
        update(() => {
          optionsHashtags.value = hashtags
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        optionsHashtags.value = optionsHashtags.value.filter(v => v.toLowerCase().indexOf(needle) > -1)
      })
    }

    const createValueHashtags = (val, done) => {
      if (val.length > 0) {
        if (!hashtags.includes(val)) {
          hashtags.push(val)
        }
        done(val, 'toggle')
      }
    }

    return { img, form, handleSubmit, isUpdated, authors, categories, optionsHashtags, filterOptionsHashtags, createValueHashtags }
  }
})
</script>
